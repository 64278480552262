@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
    @apply mb-6 text-6xl;
}

p {
    @apply mb-4 text-lg;
}

.container {
    @apply px-8 mx-auto;
}

.player-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}